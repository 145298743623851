<template>
    <div id="course" :data-course-id="course.id" class="grow flex overflow-y-auto flex-col w-full pt-4 md:pt-8">
        <div class="flex flex-col grow px-4">
            <section class="flex flex-col gap-6 max-w-5xl w-full mx-auto">
                <header class="mb-8">
                    <h2 class="text-3xl font-bold mb-4" v-text="course.title"></h2>
                    <div class="text-neutral-600 text-lg">
                        <MarkdownText :text="course.description" />
                    </div>
                </header>

                <div id="course-wrapper" class="flex mb-8 bg-white border rounded-lg border-neutral-300 h-[60vh]">
                    <div id="module-list-wrapper" class="w-1/3 bg-neutral-100 border-r border-neutral-300 overflow-y-scroll h-full">
                        <ul>
                            <template v-for="module in modules" :key="module.id">
                                <li>
                                    <button
                                        class="p-4 text-left flex justify-between"
                                        :class="currentModuleId == module.id && 'bg-neutral-200'"
                                        @click="currentModuleId = module.id"
                                    >
                                        <div v-text="module.title"></div>
                                        <div class="inline-block shrink-0 w-4 h-4 pt-1 mr-2">
                                            <ChevronRight />
                                        </div>
                                    </button>
                                </li>
                            </template>
                        </ul>
                    </div>
                    <div v-if="currentModule" id="module-content" class="w-2/3 py-8 px-6 overflow-y-scroll h-full">
                        <div class="module">
                            <h3 class="text-lg mb-4">{{ currentModule.title }}</h3>
                            <div>
                                <MarkdownText :text="currentModule.content" />
                            </div>

                            <video v-if="currentModule.video_link" controls class="mt-8">
                                <source :src="currentModule.video_link" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>

                            <div v-if="currentModule.prompt_start_url" class="mt-8">
                                <Link :href="currentModule.prompt_start_url" target="_blank" class="btn-secondary btn-rounded py-2 px-4 mt-8">
                                    <div class="inline-block shrink-0 w-6 h-6 text-white pt-1 mr-1">
                                        <NewWindow />
                                    </div>
                                    Start topic
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import LeftSidebarLayout from "~vue/layouts/LeftSidebarLayout.vue";
export default { layout: LeftSidebarLayout };
</script>

<script setup>
import { Link } from "@inertiajs/vue3";
import { computed, onMounted, ref } from "vue";

import ChevronRight from "~vue/icons/ChevronRight.vue";
import NewWindow from "~vue/icons/NewWindow.vue";
import MarkdownText from "~vue/MarkdownText.vue";

const props = defineProps({
    course: {
        type: Object,
        default: () => null,
    },
    first_module_id: {
        type: Number,
        default: () => 0,
    },
    modules: {
        type: Array,
        default: () => [],
    },
});

const currentModuleId = ref(props.first_module_id);
const currentModule = computed(() => props.modules.find((module) => module.id === currentModuleId.value));
</script>
